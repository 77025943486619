import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ['menuContent']

  connect() {
    document.body.classList.add("stop-scrolling")
  }

  disconnect() {
    document.body.classList.remove("stop-scrolling")
  }

  closedMenu() {
    document.getElementById('sidebarContent').classList.add('hidden')
  }

  toggleMenu() {
    document.getElementById('sidebarContent').classList.toggle('hidden')
  }
}
